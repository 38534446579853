import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRoot, withAuth } from 'hocs';
import { compose } from 'recompose';
import debounce from 'lodash.debounce';
import { useIsMount } from 'material-design/Statistic/StatisticCategory/StatisticByDatesSection/utils/isMountHook';

// UI
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, SelectCustom } from 'material-design/MuiStyled/MuiStyled';
import { CheckboxBlack } from 'material-design/MuiStyled/MuiStyled';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';
import MuiMultipleCheckedSkeleton from '../MuiMultipleCheckedSkeleton/MuiMultipleCheckedSkeleton';
import { Label } from 'components/Labels';

// Styles
import s from './MuiMultipleCheckedTracks.module.css';

const MultipleSelectPerformers = ({
	rootContext: {
		getAccountStatisticAllPerformers,
		getAccountStatisticAuthorAllPerformers,
	},
	dateFrom,
	dateTo,
	accountId,
	isAdmin,
	setPerformersState,
	lang,
	chartMode,
}) => {
	const [performers, setPerformers] = useState([]);
	const [checkedPerformers, setCheckedPerformers] = useState([]);
	const [cachedPerformers, setCachedPerformers] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [isShowSkeleton, setIsShowSkeleton] = useState(false);
	const isMount = useIsMount();

	const [page] = useState(1);
	const [limit] = useState(100);

	const getPerformerRequest =
		chartMode === 'compo'
			? getAccountStatisticAuthorAllPerformers
			: getAccountStatisticAllPerformers;

	const getPerformers = (checkedState = true) => {
		setIsShowSkeleton(true);
		getPerformerRequest(
			isAdmin,
			accountId,
			searchValue,
			page,
			limit,
			dateFrom,
			dateTo
		).then((res) => {
			const data = res.data.data.map((item) => ({
				...item,
				checked: !checkedState
					? checkedPerformers.includes(item.performer)
					: checkedState,
			}));

			const allPerformersButton = {
				id: 0,
				performer: lang === 'en' ? 'Select all' : 'Выбрать все',
				checked: searchValue.length > 0 ? false : true,
			};

			const performersData = data.length ? [allPerformersButton, ...data] : [];

			if (isMount && !checkedPerformers.length) {
				const allPerformers = performersData.map((item) => item.performer);
				setCheckedPerformers(allPerformers);
			}

			setPerformers(performersData);
			setIsShowSkeleton(false);
		});
	};

	useEffect(() => {
		if (searchValue.length) {
			getPerformers(false);
		} else {
			getPerformers();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateFrom, dateTo, searchValue, lang]);

	const handleSearchInput = (e) => {
		setSearchValue(e.target.value);
	};

	const debouncedSearchHandler = useCallback(
		debounce(handleSearchInput, 500),
		[]
	);

	const handleCheckedPerformer = (checkedPerformer) => {
		let mappedPerformers;

		if (checkedPerformer.id === 0 && checkedPerformer.checked) {
			mappedPerformers = performers.map((performer) => ({
				...performer,
				checked: false,
			}));

			setCheckedPerformers([]);
		}

		if (checkedPerformer.id === 0 && !checkedPerformer.checked) {
			mappedPerformers = performers.map((performer) => ({
				...performer,
				checked: true,
			}));

			setCheckedPerformers(
				mappedPerformers.map((performerName) => performerName.performer)
			);
		}

		if (!(checkedPerformer?.id === 0) && checkedPerformer.checked) {
			mappedPerformers = performers.map((performerName) => {
				if (performerName.id === 0) {
					return { ...performerName, checked: false };
				}

				if (performerName.performer === checkedPerformer.performer) {
					return { ...performerName, checked: false };
				} else {
					return performerName;
				}
			});

			setCheckedPerformers(
				checkedPerformers.filter(
					(performer) =>
						performer !== checkedPerformer.performer &&
						performer !== (lang === 'en' ? 'Select all' : 'Выбрать все')
				)
			);
		}

		if (!(checkedPerformer?.id === 0) && !checkedPerformer.checked) {
			mappedPerformers = performers.map((performerName) => {
				if (performerName?.id) {
					return { ...performerName, checked: false };
				}

				if (performerName.performer === checkedPerformer.performer) {
					return { ...performerName, checked: true };
				} else {
					return performerName;
				}
			});

			setCheckedPerformers([
				...checkedPerformers.filter(
					(performer) =>
						performer !== (lang === 'en' ? 'Select all' : 'Выбрать все')
				),
				checkedPerformer.performer,
			]);
		}

		setPerformers(mappedPerformers);
	};

	const handleClickOnly = (e, performer) => {
		e.stopPropagation();

		const mappedPerformer = performers.map((item) => ({
			...item,
			checked: item.performer === performer.performer,
		}));

		setCheckedPerformers([performer.performer]);
		setPerformers(mappedPerformer);
	};

	const handleSelectClose = () => {
		if (cachedPerformers !== checkedPerformers) {
			setPerformersState(
				checkedPerformers.filter(
					(performerName) =>
						performerName !== (lang === 'en' ? 'Select all' : 'Выбрать все')
				)
			);
		}
	};

	const handleResetFilter = () => {
		setCheckedPerformers([]);
		setPerformersState([]);
		setPerformers([]);
		setSearchValue('');
	};

	const handleSelectOpen = () => {
		setCachedPerformers(checkedPerformers);
	};

	useEffect(() => {
		if (!checkedPerformers.length && !performers.length) {
			getPerformers();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkedPerformers, performers]);

	return (
		<FormControl variant="standard" className={s.formControl}>
			<Input id="performers-select-label">
				<FormattedMessage id="rod.statistic.performers" />
			</Input>
			<SelectCustom
				labelId="performers-select-label"
				id="performers-select"
				multiple
				value={performers}
				onOpen={handleSelectOpen}
				onClose={handleSelectClose}
				MenuProps={{
					style: { zIndex: 99999999999 },
				}}
				renderValue={() => {
					if (isShowSkeleton) {
						return <FormattedMessage id={'rod.statistic.updating-data'} />;
					}

					if (performers.length === 1 && performers[0].id === 0) {
						return (
							<>
								<FormattedMessage id={'rod.statistic.selected'} /> 0{' '}
								<FormattedMessage id={'rod.statistic.selected-performers'} />
							</>
						);
					}

					if (checkedPerformers.length === 1) {
						return <span>{checkedPerformers[0]}</span>;
					} else {
						return (
							<>
								<FormattedMessage id={'rod.statistic.selected'} />{' '}
								{checkedPerformers.length === 101
									? checkedPerformers.length - 1
									: checkedPerformers.length}{' '}
								<FormattedMessage id={'rod.statistic.selected-performers'} />
							</>
						);
					}
				}}
			>
				<FormattedMessage id="rod.statistic.search-performers">
					{(label) => (
						<TextFieldInput
							className={s.search}
							autoFocus={false}
							label={label}
							initialValue={searchValue ? searchValue : ''}
							defaultValue={searchValue}
							clearOnBlur={false}
							onChange={debouncedSearchHandler}
							variant="standard"
						/>
					)}
				</FormattedMessage>

				<div className={s.wrapper}>
					{!isShowSkeleton ? (
						performers.map((performer) => (
							<MenuItem
								key={performer.performer}
								value={performer.performer}
								onClick={() => handleCheckedPerformer(performer)}
							>
								<CheckboxBlack
									checked={checkedPerformers.includes(performer.performer)}
								/>
								<Label>{performer.performer}</Label>
								<button
									className={s.btnOnly}
									onClick={(e) => handleClickOnly(e, performer)}
								>
									<FormattedMessage id={'rod.statistic.only'} />
								</button>
							</MenuItem>
						))
					) : (
						<MuiMultipleCheckedSkeleton />
					)}
				</div>
			</SelectCustom>
			<button className={s.buttonResetFilter} onClick={handleResetFilter}>
				<FormattedMessage id={'rod.statistic.reset-filter'} />
			</button>
		</FormControl>
	);
};

export default compose(withRoot, withAuth)(MultipleSelectPerformers);
