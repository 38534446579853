// Core
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

// UI
import StatisticDownloadBtn from 'components/StatisticDownloadBtn/StatisticDownloadBtn';

// Styles
import styles from './StatisticHeader.module.css';

const StatisticHeader = ({
	chartMode,
	accountId,
	statisticData,
	accountOutlets,
	isAdmin,
	trackSearchValue,
	tracksIds,
	isAllTracks,
	releaseId,
	v2,
	statType,
}) => {
	const history = useHistory();

	return (
		<div className={styles.statisticHeader}>
			<div className={styles.btnsContainer}>
				<button
					className={`${styles.statistic__chartModeBtn} ${
						chartMode === 'phono' ? styles.statistic__chartModeBtnActive : ''
					}`}
					onClick={() =>
						history.push(
							`/accounts/${accountId}/statistic/${statType}/phonograms`
						)
					}
				>
					<FormattedMessage id={'rod.repertoire.recordery'} />
				</button>
				<button
					className={`${styles.statistic__chartModeBtn} ${
						chartMode === 'compo' ? styles.statistic__chartModeBtnActive : ''
					}`}
					onClick={() =>
						history.push(
							`/accounts/${accountId}/statistic/${statType}/compositions`
						)
					}
				>
					<FormattedMessage id={'rod.repertoire.composition'} />
				</button>
			</div>
			{/* <StatisticDownloadBtn
				accountId={accountId}
				statisticData={statisticData}
				accountOutlets={accountOutlets}
				isAdmin={isAdmin}
				tracksIds={trackSearchValue ? tracksIds : isAllTracks ? [] : tracksIds}
				releaseId={releaseId}
				withTitle
				v2={v2}
				chartMode={chartMode}
			/> */}
		</div>
	);
};

export default StatisticHeader;
