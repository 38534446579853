export const toGeneralFormat = (data) => {
	return data.map((track) => {
		if (track.contributors.length) {
			track.contributors.forEach((contributor) => {
				contributor.ownership =
					contributor.ownership === '100.00'
						? '100'
						: contributor.ownership.toString();
				contributor.publisher_share = contributor.publisher_share.toString();
				contributor.publisher =
					contributor.publisher === '' ? null : contributor.publisher;
				contributor.roles = contributor.roles.map((role) =>
					role.logo ? role.logo : role
				);
			});
		}

		return track;
	});
};
